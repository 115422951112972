import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H1, P } from "../components/common/Typography";
import imageHero from "../assets/images/hero-architecture.jpg";

const TITLE = "Η αρχιτεκτονική";

function ArchitecturePage() {
  return (
    <Layout>
      <SEO title={TITLE} />
      <img
        css={`
          width: 100%;
          vertical-align: bottom;
          max-height: 476px;
          object-fit: cover;
        `}
        src={imageHero}
        alt="Architecture"
      />
      {/* <StaticImage
        alt="Architecture"
        src="../assets/images/hero-architecture.jpg"
        placeholder="blurred"
        layout="constrained"
        height={476}
      /> */}
      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          H ΘΟΔΩΡΗΣ ΠΑΝΑΣ / ΣΧΟΛΗ ΧΟΡΟΥ ΑΘΗΝΩΝ, στεγάζεται σε ένα εργοστάσιο που
          κτίστηκε τμηματικά από το 1950 - 1970. Το κέλυφος του κτηρίου
          αποτελείται από μεγάλα δοκάρια και ψηλές κολώνες για να γεφυρώνουν δύο
          μεγάλους και ψηλούς χώρους χωρίς την παρεμβολή πρόσθετων κολώνων. Τα
          μεγάλα ανοίγματα με τα χαρακτηριστικά κουφώματα (ομοιόμορφες
          υποδιαιρέσεις) αποτελούν κύριο χαρακτηριστικό του χώρου και επιτρέπουν
          τον φυσικό φωτισμό του. Όλο το δάπεδο είναι μωσαϊκό και υπάρχει ένα
          πατάρι στο οποίο ανεβαίνει κανείς μέσω μιας μεταλλικής σκάλας, που
          έχει διαμορφωθεί σε ένα άνετο Café.
        </P>
        <P>
          Αυτά τα χαρακτηριστικά του κτηρίου, τα οποία μας έκαναν εντύπωση από
          την πρώτη στιγμή, θέλαμε να σεβαστούμε και να διατηρήσουμε. Το
          κτιριολογικό πρόγραμμα ζητούσε τρεις μεγάλες αίθουσες διδασκαλίας, ένα
          χώρο υποδοχής (reception), ένα χώρο γραφείου, ένα χώρο αναμονής,
          αποδυτήρια για άνδρες, γυναίκες και παιδιά.
        </P>
        <P>
          Ζυγίζοντας τα παραπάνω στοιχεία αποφασίσαμε να τοποθετήσουμε τις
          αίθουσες στο μεγαλύτερο και κεντρικότερο τμήμα του χώρου και τις
          δευτερεύουσες χρήσεις περιμετρικά. Με αυτόν τον τρόπο μπορέσαμε να
          δώσουμε τις μέγιστες διαστάσεις στις αίθουσες που είναι και η κύρια
          χρήση της Σχολής και να διατηρήσουμε το ύψος και την αίσθηση
          ευρυχωρίας που ήδη είχε ο χώρος. Οι δευτερεύουσες χρήσεις πλαισιώνουν
          τις αίθουσες και αποτελούν από μόνες τους μονάδες αρχιτεκτονικού
          ενδιαφέροντος, με την κυβιστική προσέγγιση που τις χαρακτηρίζει.
        </P>
        <P>
          Θέλοντας να δώσουμε προτεραιότητα στους χρήστες, αποφασίσαμε η σχολή
          να είναι λευκή με μαύρες τις μεταλλικές λεπτομέρειες. Οι χρήστες με
          την κίνησή τους και τα χρώματά τους θα "ντύσουν" το χώρο. Θέλοντας να
          τονίσουμε τη ροή της κίνησης αποφασίσαμε η γωνία της πρώτης αίθουσας
          να γίνει μία μεγάλη καμπύλη που οδηγεί τους χρήστες μέσα στις
          αίθουσες. Απέναντι από τη μεγάλη καμπύλη υπάρχει η μικρή, της
          υποδοχής, δημιουργώντας το "στενό εισόδου" στα μαθήματα.
        </P>
        <P>
          Το άπλετο φώς που απολαμβάνει ο χώρος αναμονής μάς θύμισε εξωτερικό
          χώρο και έτσι αντιμετωπίστηκε με μια διάθεση παιχνιδιού στη φύση, με
          την τοποθέτηση γκαζόν και πολύχρωμων πλαστικών πολυθρόνων. Επίσης,
          επιτρέπει την ανέμελη χρήση από μικρά παιδιά. Εξωτερικά στην όψη του
          κτηρίου συνεχίζεται η ίδια προσέγγιση με το ασπρόμαυρο του εσωτερικού
          χώρου με την πινελιά του κίτρινου. Διαλέξαμε το κίτρινο λόγω της
          φωτεινότητας και της αισιοδοξίας που μεταδίδει.
        </P>
        <P>ΠΑΡΑΣΚΕΥΗ ΓΚΟΓΚΟΡΩΣΗ</P>
        <P>ΑΡΧΙΤΕΚΤΩΝ ΜΗΧΑΝΙΚΟΣ ΕΜΠ</P>
      </Section.Main>
    </Layout>
  );
}

export default ArchitecturePage;
